<template>
  <div class="content styles">
    <banner title="Elements & Styles"> </banner>

    <div class="section">
      <div class="content_wrapper">
        <h3>Fonts and Paragraph styles</h3>
        <p>Font family: Nunito Sans + PT Serif <br />Six Styles:</p>
        <h1>H1 Title</h1>
        <h2>H2 Title</h2>
        <h3>H3 Title</h3>
        <h4>H4 Title</h4>
        <h5>H5 Title</h5>
        <h6>H6 Title</h6>
        <p>
          Paragraph Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut
          repellat officiis enim eos perspiciatis, eveniet, vel animi sapiente
          sequi rem facere dolorum velit nemo asperiores laborum necessitatibus
          consectetur reiciendis deserunt.
          <a class="link" href="">Link click me</a>
        </p>

        <img class="image" src="../assets/images/pic1.jpg" alt="Sample image" />
        <caption>
          Caption Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Veniam harum dolores quos deserunt alias sed ducimus odit repellendus,
          consequatur deleniti exercitationem enim nobis. Facilis ipsa fugiat
          eligendi laboriosam deleniti obcaecati.
        </caption>

        <table>
          <thead>
            <tr>
              <th class="accent">Company</th>
              <th>Contact</th>
              <th>Country</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Shell PLC</td>
              <td>Maria Anders</td>
              <td>Germany</td>
            </tr>
            <tr>
              <td>TotalEnergies SE</td>
              <td>Francisco Chang</td>
              <td>Mexico</td>
            </tr>
            <tr>
              <td>Chevron Corp.</td>
              <td>Roland Mendel</td>
              <td>Austria</td>
            </tr>
            <tr>
              <td>BP PLC</td>
              <td>Helen Bennett</td>
              <td>UK</td>
            </tr>
            <tr>
              <td>Marathon Petroleum Corp.</td>
              <td>Yoshi Tannamuri</td>
              <td>Canada</td>
            </tr>
            <tr>
              <td>Valero Energy Corp.</td>
              <td>Giovanni Rovelli</td>
              <td>Italy</td>
            </tr>
          </tbody>
        </table>

        <h6>UL List</h6>
        <p>
          Items that do not have a numerical ordering. Lorem ipsum dolor sit
          amet consectetur, adipisicing elit. Tempore libero quo ea esse
          nostrum. Quam a ea, esse aperiam quasi, quae nesciunt nihil voluptas
          reiciendis tempora nostrum, doloremque eius. Tenetur.
        </p>
        <ul>
          <li>Exploration and drilling</li>
          <li>Refining and processing</li>
          <li>Oil rig operations</li>
          <li>Natural gas extraction</li>
          <li>Pipeline transportation</li>
          <li>Oil reserves and reserves estimation</li>
          <li>Environmental impact assessments</li>
          <li>Oil and gas pricing</li>
          <li>Energy trading and markets</li>
          <li>Offshore platforms</li>
        </ul>

        <h6>Buttons</h6>

        <button class="default">Click me</button>
        <button class="info">Click me</button>
        <button class="default" disabled>Disabled</button>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "Styles-view",
  components: {
    Banner,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
